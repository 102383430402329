<template>
    <el-dialog v-if="m" :title="title" :visible.sync="isShow" width="650px" top="10vh" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
        <div class="vue-box">
            <!-- 参数栏 -->
            <div class="c-panel">
                <el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm" label-width="120px">
                    <el-form-item label="任务名称：" prop="title">
                        <el-input v-model="m.title"></el-input>
                    </el-form-item>
                    <el-form-item label="项目id：" prop="projectId">
                        <select-project v-model="m.projectId"></select-project>
                    </el-form-item>
                    <el-form-item label="任务类型">
                        <input-enum enumName="indexTaskTypeEnum" v-model="m.type"></input-enum>
                    </el-form-item>
                    <el-form-item label="优先级别">
                        <input-enum enumName="indexTaskLevelEnum" v-model="m.level"></input-enum>
                    </el-form-item>

                    <el-form-item label="指派给：">
                         <select-user v-model="m.userId"></select-user>
                    </el-form-item>
                   

                    <el-form-item label="重要级别：" prop="important">
                        <el-select v-model="m.important">
                            <el-option value="A"></el-option>
                            <el-option value="B"></el-option>
                            <el-option value="C"></el-option>
                            <el-option value="D"></el-option>
                            <el-option value="E"></el-option>
                        </el-select>

                    </el-form-item>
                    <el-form-item label="结束：" prop="startDate">
                        <el-date-picker v-model="m.startDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始日期"></el-date-picker>
                        -<el-date-picker v-model="m.endDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束日期"></el-date-picker>
                    </el-form-item>

                    <el-form-item>
                        <span class="c-label">&emsp;</span>
                        <el-button type="primary" icon="el-icon-plus" size="small" @click="ok('ruleForm')">确定
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import inputEnum from "../../sa-resources/com-view/input-enum.vue";
import selectProject from "../../sa-resources/com-view/selelct-project.vue";
import SelectUser from "../../sa-resources/com-view/select-user.vue";
export default {
    components: { inputEnum, selectProject, SelectUser },
    props: ["params"],
    data() {
        return {
            m: {},
            title: "",
            isShow: false,
            rules: {
                title: [
                    {
                        required: true,
                        message: "请输入任务名称",
                        trigger: "blur"
                    }
                ],
                projectId: 0,
                type: [],
                level: [],
                important: [],
                startDate: [],
                endDate: [],
                state: []
            },
            fileList: []
        };
    },
    methods: {
        open: function(data) {
            this.isShow = true;
            if (data) {
                this.title = "首页代办任务";

                this.m = data;
            } else {
                this.m = {
                    title: "",
                    projectId: "",
                    type: "",
                    level: "",
                    important: "",
                    startDate: "",
                    endDate: "",
                    state: "",
                    userId: ""
                };
                this.title = "首页代办任务";
            }
        },

        //提交首页代办任务信息
        ok: function(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.m.state = 0;
                    this.sa.post("/indexTask/save", this.m).then(res => {
                        this.isShow = false;
                        this.$parent.f5();
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    },
    created() {}
};
</script>